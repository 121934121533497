<template>
    <div style="background-color: var(--v-background-base) !important">
        <v-toolbar color="greyBase" dark extended flat extension-height="55">
            <v-toolbar-title>
                <v-btn @click="$router.back()" icon>
                    <v-icon>arrow_back</v-icon>
                </v-btn>
                {{  item.name  }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
          'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
        }"> 
        <v-card-text
          :style="{
            height: !$vuetify.breakpoint.mobile ? '100%' : '90vh',
            'overflow-y': 'auto' ,
          }"
        > 
        <v-tabs v-model="activeTab" height="35px" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab" :href="`#${tab}`">
                {{  tab  }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
            <v-tab-item value="Info">
                <OrganisationInfo />
            </v-tab-item>
            <v-tab-item value="Subscriptions">
                <OrganisationSubscription />
            </v-tab-item>
            <v-tab-item value="Users">
               <OrganisationUsers :orgId="item.id"/>
            </v-tab-item>
            <v-tab-item value="Roles">
               <OrganisationRoles :orgId="item.id"/>
            </v-tab-item>
            <v-tab-item value="Teams">
               <OrganisationTeam :orgId="item.id"/>
            </v-tab-item>
            <v-tab-item value="Integrations">
               <OrganisationIntegrations :orgId="item.id"/>
            </v-tab-item>
            <v-tab-item value="FBOs">
               <OrganisationProductionUnits :orgId="item.id"/>
            </v-tab-item>
        </v-tabs-items>

        <!-- Add modules dialog -->
        <el-dialog :title="'Add Modules'" :visible.sync="moduleDialog" :fullscreen="$vuetify.breakpoint.mobile"
            width="850px" :closed="clearModules">
            <v-row justify="end">

                <v-col cols="12" sm="12" md="8" lg="6">
                    <el-input suffix-icon="el-icon-search" v-model="searchSystemModules" clearable placeholder="Search">
                        <template slot="prepend" v-if="tempModules.length > 0">
                            {{  tempModules.length  }} selected
                        </template>

                    </el-input>
                </v-col>
            </v-row>
            <el-container>
                <el-main>
                    <el-table v-loading="loading" :data="filterModules" ref="moduleTable" size="small"
                        @selection-change="moduleSelectionChange">
                        <el-table-column type="selection" width="55" :selectable="filterSelectableModules">
                        </el-table-column>
                        <el-table-column prop="name" sortable label="Name" header-align="center" align="center">
                        </el-table-column>
                    </el-table>
                </el-main>
                <el-footer>
                    <el-row justify="center" type="flex">
                        <el-button type="primary" :disabled="tempModules.length == 0" @click="addModuleToOrg()">Save
                        </el-button>
                    </el-row>
                </el-footer>
            </el-container>
        </el-dialog>

        <!-- Add/Edit Role dialog -->
        <el-dialog :title="roleItem.modalType + 'Role'" :visible.sync="roleDialog"
            :fullscreen="$vuetify.breakpoint.mobile" width="850px"
            :closed="() => { roleItem = {}, roleDialog = false }">
            <el-form class="modal-form" :model="roleItem" ref="form" @submit.native.prevent="saveRole">
                <el-form-item prop="name">
                    <el-input v-model="roleItem.name" placeholder="Name" prefix-icon="fas fa-user" clearable>
                        <v-icon v-if="roleItem.name" slot="suffix" class="mr-1" small color="green">check</v-icon>
                        <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                    </el-input>
                </el-form-item>
                <el-form-item prop="description">
                    <el-input type="textarea" v-model="roleItem.description" placeholder="Description"
                        prefix-icon="fas fa-user" clearable>
                        <v-icon v-if="roleItem.description" slot="suffix" class="mr-1" small color="green">check
                        </v-icon>
                        <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                    </el-input>
                </el-form-item>

                <el-form-item style="text-align: center">
                    <el-button v-if="roleItem.modalType == 'Add'" :loading="loading" style="min-width: 40%"
                        type="primary" :disabled="!roleItem.name" native-type="submit" block> {{  !loading ? 'Submit' :
                        ''

                        }}</el-button>
                    <el-button v-else :loading="loading" style="min-width: 40%" type="primary"
                        :disabled="!roleItem.name" native-type="submit" block> {{  !loading ? 'Save' : ''  }}</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!-- Add users dialog -->
        <el-dialog :title="'Add Users to ' + item.name" :visible.sync="userDialog"
            :fullscreen="$vuetify.breakpoint.mobile" width="850px" :closed="clearUsers">
            <v-row justify="end">
                <v-col cols="12" sm="12" md="4" lg="6" class="text-left">
                    <el-button v-if="userParams.filter && Object.keys(userParams.filter).length > 0" type="plain"
                        @click="clearUserFilter" color="error">
                        <v-icon small>close</v-icon> Clear Filters
                    </el-button>
                </v-col>
                <v-col cols="12" sm="12" md="8" lg="6">
                    <el-input suffix-icon="el-icon-search" v-model="userParams.search" clearable placeholder="Search"
                        @keyup.enter.native="getUsers()">
                        <template slot="prepend" v-if="tempUsers.length > 0">
                            {{  tempUsers.length  }} selected
                        </template>

                    </el-input>
                </v-col>
            </v-row>
            <el-container>
                <el-main>
                    <el-table v-loading="loading" :data="users.data" ref="userTable" size="small"
                        @filter-change="filterUserChange" @selection-change="userSelectionChange">
                        <el-table-column type="selection" width="55" :selectable="filterSelectableUsers">
                        </el-table-column>
                        <el-table-column prop="firstname" sortable label="Name" header-align="center" align="center">
                        </el-table-column>
                        <el-table-column prop="surname" sortable label="Surname" header-align="center" align="center">
                        </el-table-column>
                        <el-table-column prop="user.emailAddress" sortable label="Email" header-align="center"
                            align="center"></el-table-column>
                        <el-table-column column-key="hidden" prop="hidden" sortable label="Visibility"
                            header-align="center" align="center"
                            :filters="[{ text: 'Hidden', value: true }, { text: 'Visible', value: false }]"
                            filter-placement="bottom-end">
                            <template slot-scope="scope">
                                <el-tag style="cursor: pointer" :type="scope.row.hidden ? 'primary' : 'danger'">
                                    {{  scope.row.hidden ? 'Hidden' : 'Visible'  }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column column-key="isActive" prop="isActive" sortable label="Status"
                            header-align="center" align="center"
                            :filters="[{ text: 'Active', value: true }, { text: 'Inactive', value: false }]"
                            filter-placement="bottom-end">
                            <template slot-scope="scope">
                                <el-tag style="cursor: pointer" :type="scope.row.isActive ? 'primary' : 'danger'">
                                    {{  scope.row.isActive ? 'Active' : 'Inactive'  }}</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-main>
                <el-footer>
                    <el-row justify="center" type="flex">
                        <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="userPage"
                            :page-size.sync="userParams.limit" :page-sizes="[10, 25, 50, 100]"
                            layout="sizes, prev, pager, next, jumper" :total="users.total">
                        </el-pagination>
                    </el-row>
                    <el-row justify="center" type="flex">
                        <el-button type="primary" :disabled="tempUsers.length == 0" @click="addUsersToOrg()">Save
                        </el-button>
                    </el-row>
                </el-footer>
            </el-container>
        </el-dialog>
    </v-card-text>
    </v-card>
    
    </div>
</template>

<script>
import OrganisationInfo from './OrganisationInfo.vue'
import OrganisationUsers from './OrganisationUsers.vue'
import OrganisationRoles from './OrganisationRoles.vue'
import OrganisationTeam from './OrganisationTeam.vue'
import OrganisationIntegrations from './OrganisationIntegrations.vue'
import OrganisationSubscription from './OrganisationSubscription.vue'
import OrganisationProductionUnits from './OrganisationProductionUnits.vue'

export default {
    components:{
        OrganisationInfo,
        OrganisationUsers,
        OrganisationRoles,
        OrganisationTeam,
        OrganisationIntegrations,
        OrganisationSubscription,
        OrganisationProductionUnits
    },
    data: () => ({
        edit: false,
        item: {},
        userHeaders: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'user.firstname',
            align: 'center',
        },
        {
            text: 'Surname',
            value: 'user.surname',
            align: 'center',
        },
        {
            text: 'Email',
            value: "user.emailAddress",
            align: 'center',
        },
        {
            text: 'Position',
            value: "position",
            align: 'center',
        },
        {
            text: 'Shared',
            value: "shared",
            align: 'center',
        },
        {
            text: 'Auth Provider',
            sortable: false,
            value: "user.authProvider",
            align: 'center',
        },

        {
            text: 'Roles',
            value: 'roles',
            sortable: false,
            align: 'center',
            width: '250px'
        }
        ],
        addressModal: false,
        addressItem: {},
        addressKey: 0,
        logoList: [],
        iconList: [],
        loading: false,
        center: {
            lng: 18.427059,
            lat: -33.919473,
        },
        zoom: 12,
        key: 0,
        modules: [],
        moduleDialog: false,
        moduleSearch: '',
        searchSystemModules: '',
        orgUsers: [],
        orgModules: [],
        roles: [],
        roleDialog: false,
        roleItem: {},
        roleSearch: '',
        tabRefresh: 0,
        tempModules: [],
        tempUsers: [],
        users: {
            count: 0,
            data: []
        },
        userDialog: false,
        userPage: 1,
        userParams: {
            limit: 10,
            offset: 0,
            search: null
        },
        userSearch: '',
        activeTab: 0,
        tabs: ['Info', 'Subscriptions', 'Users', 'Roles', 'Teams', 'Integrations', 'FBOs'],
        isGroupItems: [
            {
                text: "True",
                value: true
            },
            {
                text: "False",
                value: false
            }
        ]
    }),
    computed: {
        filterOrgUsers() {
            let result = this.orgUsers
            if (this.userSearch) {
                result = result.filter(x =>
                    (x.user.firstname.toLowerCase()).includes(this.userSearch.toLowerCase()) ||
                    (x.user.surname.toLowerCase()).includes(this.userSearch.toLowerCase()) ||
                    (x.user.emailAddress.toLowerCase()).includes(this.userSearch.toLowerCase()) ||
                    (x.user.authProvider.toLowerCase()).includes(this.userSearch.toLowerCase())
                )
            }
            return result
        },
        filterModules() {
            let result = this.modules
            if (this.searchSystemModules) {
                result = result.filter(x =>
                    (x.name.toLowerCase()).includes(this.searchSystemModules.toLowerCase())
                )
            }
            return result
        },
        filterOrgModules() {
            let result = this.orgModules
            if (this.moduleSearch) {
                result = result.filter(x =>
                    (x.systemModule.name.toLowerCase()).includes(this.moduleSearch.toLowerCase())
                )
            }
            return result
        },
        filterRoles() {
            let result = this.roles
            if (this.roleSearch) {
                result = result.filter(x =>
                    (x.name.toLowerCase()).includes(this.roleSearch.toLowerCase())
                )
            }
            return result
        },
    },
    watch: {
        '$route.params.id': {
            immediate: true,
            handler(value) {
                if (value) {
                    this.loadOrganisation(value)
                }
            }
        },
        async userPage(value) {
            this.userParams.offset = (value - 1) * this.userParams.limit
            await this.getUsers()
        },
        'userParams.limit': {
            immediate: true,
            async handler() {
                await this.getUsers()

            }
        },
    },
    async created() {
        await this.getUsers()
    },
    methods: {
        async addUsersToOrg() {
            await this.$API.createOrganisationUserAdmin({
                batch: this.tempUsers.map(x => ({
                    organisationId: this.item.id,
                    userId: x.id
                }))
            })
            this.clearUsers()
            this.getOrganisationUsers(this.item.id)
        },
        addUserToRole(user) {
            let index = this.orgUsers.indexOf(this.orgUsers.find(x => x.id == user.id))
            this.orgUsers[index].addRole = true
            this.orgUsers[index].userRoles = this.orgUsers[index].user.userRoles.map(x => x.roleId)
            this.tabRefresh++
            this.$nextTick(() => {
                this.$refs['addToRoleInput_' + user.id].$refs.input.focus();
            });
        },
        async processUserRoleChanges(data) {
            let index = this.orgUsers.indexOf(this.orgUsers.find(x => x.id == data.id))
            this.orgUsers[index].addRole = false
            let add = data.userRoles.filter(x => !data.user.userRoles.map(y => y.roleId).includes(x))
            let remove = data.user.userRoles.filter(x => !data.userRoles.includes(x.roleId))
            let obj = [...add.map(x => ({
                userId: data.userId,
                roleId: x
            })), ...remove.map(x => ({
                id: x.id,
                isActive: false,
                isDeleted: true
            }))]
            if (obj.length > 0) {
                await this.$API.createUserRoleAdmin({
                    batch: obj
                })
                this.getOrganisationUsers(this.item.id)
            }
            this.tabRefresh++
        },
        async addModuleToOrg() {
            await this.$API.createOrganisationModule({
                batch: this.tempModules.map(x => ({
                    organisationId: this.item.id,
                    systemModuleId: x.id
                }))
            })
            this.clearModules()
            this.getOrganisationModules(this.item.id)
        },
        clearFilter() {
            this.$refs.userTable.clearFilter();
            this.userParams.filter = {}
            this.getUsers()
        },
        clearUsers() {
            this.tempUsers = []
            this.userDialog = false
        },
        clearModules() {
            this.tempModules = []
            this.moduleDialog = false
        },
        createFilter(queryString) {
            return (link) => {
                return (link.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        editRole(role) {
            this.roleItem = role
            this.roleItem.modalType = 'Edit'
            this.roleDialog = true
        },
        filterSelectableModules(row) {
            let selectedScopes = this.orgModules.map(x => x.systemModuleId)
            return !selectedScopes.includes(row.id);
        },
        filterSelectableUsers(row) {
            let selectedScopes = this.orgUsers.map(x => x.userId)
            return !selectedScopes.includes(row.id);
        },
        filterUserChange(data) {
            this.userParams.filter = data
            this.getUsers()
        },
        async loadOrganisation(id) {
            this.item = await this.$API.getOrganisationAdmin(id)
            this.getOrganisationUsers(id)
            this.getOrganisationModules(id)
            this.getRoles(id)
        },
        async getOrganisationUsers(id) {
            this.orgUsers = await this.$API.getOrganisationUsersAdmin(id)
        },
        async getOrganisationModules(id) {
            this.orgModules = await this.$API.getOrganisationModuleAdmin(id)
        },
        async getRoles(id) {
            this.roles = await this.$API.getOrganisationRoleAdmin(id)
        },
        async getUsers() {
            this.loading = true
            this.users = await this.$API.getUsersAdmin({
                params: this.userParams
            })
            this.loading = false
        },
        moduleSelectionChange(data) {
            this.tempModules = data
        },
        queryRoleSearch(queryString, cb) {
            var links = this.roles;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            // call callback function to return suggestions
            cb(results);
        },
        async removeRole(role) {
            this.$confirm('Are you sure you want to delete this role?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateRoleAdmin({
                    id: role.id,
                    isActive: false,
                    isDeleted: true
                })
                this.roles.splice(this.roles.indexOf(role), 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        async removeUser(user) {
            this.$confirm('Are you sure you want to delete this user?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateOrganisationUserAdmin({
                    id: user.id,
                    isActive: false,
                    isDeleted: true
                })
                this.orgUsers.splice(this.orgUsers.indexOf(user), 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        async removeModule(module) {
            this.$confirm('Are you sure you want to delete this module?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateOrganisationModule({
                    id: module.id,
                    isActive: false,
                    isDeleted: true
                })
                this.orgModules.splice(this.orgModules.indexOf(module), 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        async saveRole() {
            if (this.roleItem.id) {
                await this.$API.updateRoleAdmin(this.roleItem)
                this.roleDialog = false
                this.roleItem = {}
                this.getRoles()

            } else {
                await this.$API.createRoleAdmin(this.roleItem)
                this.roleDialog = false
                this.roleItem = {}
                this.getRoles()
            }
        },
        async updateOrganisation() {
            await this.$API.updateOrganisationAdmin(this.item)
            this.$message.success('Successfully updated!')
            this.edit = false
        },
        async updateShareStatus(user) {
            try {
                user.shared = !user.shared
                await this.$API.updateOrganisationUserAdmin({
                    id: user.id,
                    shared: user.shared
                })
                this.$message({
                    type: 'success',
                    message: 'Successfully updated!'
                });
            } catch (e) {
                this.$message({
                    type: 'info',
                    message: 'Update failed'
                });
            }
        },
        userSelectionChange(data) {
            this.tempUsers = data
        },
        toDataUrl(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        },
        async submitUpload(obj) {
            await this.$API.uploadLogo(obj);
            this.loadOrganisation();
        },
        async submitIconUpload(obj) {
            this.loadingIcon = true;
            await this.$API.uploadIcon(obj);
            this.loadOrganisation();
            this.loadingIcon = false;
        },

        checkImageDimensions(url, callback) {
            const img = new Image();
            img.onload = function() {
                callback(this.width, this.height);
            }
            img.src = url;
        },
        async logoChange(file, fileList) {
            console.log(file, fileList);
            let url = await URL.createObjectURL(file.raw);
            this.checkImageDimensions(url, (width, height) => {
                if (!(width == 192 && height == 192)) {
                    this.$message.error(`Logo must be 192x192px, currently ${width}x${height}`);
                    this.logoKey++
                    this.loadingLogo = false
                    return;
                }
                else {
                    this.toDataUrl(url, (data) => {
                        this.submitUpload({
                        name: file.name,
                        data: data,
                        type: file.raw.type,
                        });
                    });
                }
            });
        },
        async iconChange(file, fileList) {
            console.log(file, fileList);
            this.loadingIcon = true;
            let url = await URL.createObjectURL(file.raw);
            this.checkImageDimensions(url, (width, height) => {
                if (!(width == 64 && height == 64)) {
                    this.$message.error(`Icon must be 64x64px, currently ${width}x${height}`);
                    this.iconKey++
                    this.loadingIcon = false
                    return;
                }
                else {
                    this.toDataUrl(url, (data) => {
                        this.submitIconUpload({
                        name: file.name,
                        data: data,
                        type: file.raw.type,
                        });
                    });
                }
            })
        },
        async initMapAutocomplete() {
            console.log("Autocomplete initiated");
            const input = document.getElementById("pac-input");
            const autocomplete = new this.google.maps.places.Autocomplete(input, {});
            this.google.maps.event.addListener(
                autocomplete,
                "place_changed",
                async () => {
                    const place = autocomplete.getPlace();
                    this.addressItem.position = {
                        type: "Point",
                        coordinates: [
                            place.geometry.location.lng(),
                            place.geometry.location.lat(),
                        ],
                    };
                    this.center = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    };

                    for (let i = 0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types.includes("street_number")) {
                            this.addressItem.addressLine1 =
                                place.address_components[i].short_name;
                        }
                        if (place.address_components[i].types.includes("route")) {
                            this.addressItem.addressLine1 +=
                                " " + place.address_components[i].long_name;
                        }
                        if (place.address_components[i].types.includes("sublocality")) {
                            this.addressItem.addressLine2 =
                                place.address_components[i].long_name;
                        }
                        if (
                            place.address_components[i].types.includes(
                                "administrative_area_level_2"
                            )
                        ) {
                            this.addressItem.addressLine2 +=
                                ", " + place.address_components[i].long_name;
                        }
                        if (
                            place.address_components[i].types.includes(
                                "administrative_area_level_1"
                            )
                        ) {
                            this.addressItem.addressLine3 =
                                place.address_components[i].long_name;
                        }
                        if (place.address_components[i].types.includes("country")) {
                            this.addressItem.addressLine4 =
                                place.address_components[i].long_name;
                        }
                        if (place.address_components[i].types.includes("postal_code")) {
                            this.addressItem.postalCode =
                                place.address_components[i].short_name;
                        }
                        this.zoom = 16;
                    }
                    this.key++;
                    this.addressKey++;
                }
            );
        },
        addAddress() {
            this.addressItem = {
                modalType: "Add",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                addressLine4: "",
            };
            setTimeout(() => {
                this.$refs.mapRef.$mapPromise.then(() => {
                    this.initMapAutocomplete();
                });
            }, 2000);
            this.addressModal = true;
        },
        async saveAddress() {
            if (this.addressItem.id) {
                await this.$API.updateOrganisationAddress(this.addressItem);
            } else {
                let result = await this.$API.createOrganisationAddress(
                    this.addressItem
                );
                this.item.organisationAddresses.push(result);
            }
            this.addressModal = false;
            this.addressItem = {};
        },
        editAddress(item) {
            this.addressItem = item;
            this.addressItem.modalType = "Edit";
            if (this.addressItem.position) {
                this.center = {
                    lat: this.addressItem.position.coordinates[1],
                    lng: this.addressItem.position.coordinates[0],
                };
            }
            this.addressModal = true;
        },
        removeAddress(item) {
            this.$confirm(
                `Are you sure you want to remove this address?`,
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            )
                .then(async () => {
                    await this.$API.updateOrganisationAddress({
                        id: item.id,
                        isActive: false,
                        isDeleted: true,
                    });
                    this.item.organisationAddresses.splice(
                        this.item.organisationAddresses.indexOf(item),
                        1
                    );
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Delete canceled",
                    });
                });
        },
    }
}
</script>

<style>
#parent {
    overflow: visible;
}
</style>
